import { Injectable, signal, WritableSignal } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LoadingStateService {
  private loading$: WritableSignal<boolean> = signal(false);

  setLoading(loading: boolean) {
    this.loading$.set(loading);
  }

  isLoading(): WritableSignal<boolean> {
    return this.loading$;
  }
}
